<template>
  <div>
    <img mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/inner_11.jpg">
    <img mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/inner_12.jpg">
    <img mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/inner_13.jpg">
    <img mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/inner_14.jpg">
    <img mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/inner_15.jpg">
    <img mode="widthFix" src="https://cdn.cailu88.com/jingxuanshi/inner_16.jpg">
  </div>

</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>
div {
  overflow: hidden;
  line-height: 0;
}

img {
  width: 100%;
  display: inline-block;
}
</style>
